import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import { DefaultExternalLink, DefaultLink, H1 } from '../styles/global';
import { colors, screenSize } from '../styles/variables';

const Container = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: ${colors.red};
`;

const Wrapper = styled.div`
  max-width: 500px;
  background-color: ${colors.white};
  padding: 1rem;
  text-align: center;
  margin: 0 1rem;

  @media (min-width: ${screenSize.mdMin}) {
    padding: 2rem;
  }
`;

const ThankYou = () => (
  <Layout metadata="">
    <Container>
      <Wrapper>
        <H1>Tack!</H1>
        <p>
          Tack för att du kontaktar oss! Vi återkommer så fort vi kan! Ring oss
          på{' '}
          <DefaultExternalLink href="tel:086698295">
            08-669 82 95
          </DefaultExternalLink>{' '}
          ifall du behöver komma i kontakt med oss omgående.
        </p>
        <DefaultLink to="/">&#60; Tillbaka till sidan</DefaultLink>
      </Wrapper>
    </Container>
  </Layout>
);

export default ThankYou;
